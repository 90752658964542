import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  //baseUrl = 'http://localhost:8080';
  baseUrl = 'https://msquare.azurewebsites.net';

  constructor( private http: HttpClient) { }

  saveCallbackRequest(name: string, phone: string, time: string, purpose: string, email: string, description: string): Observable<any> {
    return this.http.post(this.baseUrl + '/save-lifeguard-callback-req', {name, phone, time, purpose, email, description});
  }

  saveServiceEnquery(type,q1,q2,q3,q4,q5,q6,q7,q8,q9,q10): Observable<any> {
    return this.http.post(this.baseUrl + '/save-lifeguard-service-enquery', {type,q1,q2,q3,q4,q5,q6,q7,q8,q9,q10});
  }

  
}
